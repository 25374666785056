import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { connectAutoComplete } from "react-instantsearch-dom"
import AutoSuggest from "react-autosuggest"

class AutoComplete extends Component {
    static propTypes = {
        hits: PropTypes.arrayOf(PropTypes.object).isRequired,
        currentRefinement: PropTypes.string.isRequired,
        refine: PropTypes.func.isRequired,
        onSuggestionSelected: PropTypes.func.isRequired,
        onSuggestionCleared: PropTypes.func.isRequired,
    }

    state = {
        value: this.props.currentRefinement,
    }

    onChange = (_, { newValue }) => {
        if (!newValue) {
            this.props.onSuggestionCleared()
        }
        this.setState({
            value: typeof newValue !== "undefined" ? newValue : "",
        })
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.props.refine(value)
    }

    onSuggestionsClearRequested = () => {
        this.props.refine()
    }

    getSuggestionValue(hit) {
        return hit.name
    }

    renderSuggestion(hit) {
        return (
            <div className="suggestion--result">
                <Link replace to={hit.fields.slug}>
                    {console.log("hit.fields.slug", hit.fields.slug)}
                    {hit.frontmatter.title}
                </Link>
            </div>
        )
    }

    render() {
        const { hits, onSuggestionSelected } = this.props
        const { value } = this.state

        const inputProps = {
            placeholder: "Zoek een kunstwerk...",
            onChange: this.onChange,
            value,
        }

        return (
            <AutoSuggest
                suggestions={hits}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />
        )
    }
}

export default connectAutoComplete(AutoComplete)
